import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Product from "../components/product"

const ProductPage = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <Product
        limited
        title="Aperitivo + Ramen"
        description={description}
        shortDescription="Set of two limited edition art prints ‘Ramen’ and ‘Aperitivo’. Both printed beautifully on Hahnemühle museum etching 350 gsm paper using the highest quality museum grade giclée pigment printing, ensuring lasting intense colors and rich tone depth."
        price={90}
        images={[
          data.aperitivoramen_1,
          data.aperitivoramen_2,
          data.aperitivoramen_3,
        ]}
        social={data.social}
        gumroadlink="https://gum.co/MTqkc"
        slug="/aperitivoramen/"
      />
    </>
  )
}

const query = graphql`
  fragment image on File {
    childImageSharp {
      fluid(maxWidth: 1340, quality: 95) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    social: file(relativePath: { eq: "aperitivoramen_thumb.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    aperitivoramen_1: file(relativePath: { eq: "aperitivo_1.jpg" }) {
      ...image
    }
    aperitivoramen_2: file(relativePath: { eq: "ramen_1.jpg" }) {
      ...image
    }
    aperitivoramen_3: file(relativePath: { eq: "aperitivo_2.jpg" }) {
      ...image
    }
  }
`
const description = (
  <>
    <p>Set of two limited edition art prints ‘Ramen’ and ‘Aperitivo’</p>

    <p>
      Both printed beautifully on Hahnemühle museum etching 350 gsm paper using
      the highest quality museum grade giclée pigment printing, ensuring lasting
      intense colors and rich tone depth.
    </p>

    <p>Size: 18 cm × 24cm</p>

    <p>Numbered edition of 100.</p>

    <p>
      All prints are made on heavy quality paper, so all prints are shipped flat
      packaged to ensure delivery of the print in mint condition.
    </p>
  </>
)

export default ProductPage
